<template>
  <div>

    <app-navigation :title="$route.params.id ? $t('accessory.camera.edit') : $t('accessory.camera.add')" :backButtonText="$t('navigation.back')"></app-navigation>


    <el-form :model="accessoryData" ref="accessoryForm" class="form-container" label-position="left" label-width="220px" status-icon>

      <el-form-item prop="name" :label="$t('accessory.accessory-name')" style="width: 400px" :rules="[{required: true, message: $t('accessory.form-rules.type-name')}]">
        <el-input type="text" v-model="accessoryData.name" auto-complete="on" :placeholder="$t('accessory.type-accessory-name')">
        </el-input>
      </el-form-item>


      <el-form-item :label="$t('accessory.icon')">

        <el-col :span="8">
          <el-select v-model="accessoryData.icon" filterable :placeholder="$t('accessory.select-icon')">
            <el-option v-for="(icon, index) in icons" :key="index" :label="icon.title" :value="icon.value">
              <span class="icon-select-layout"><img @error="onImageError" :src="'/assets/icons/' + icon.value + '.png'" class="icon-select">{{icon.title}}</span>
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="16">
              <span>
                <img @error="onImageError" :src="'/assets/icons/' + accessoryData.icon + '.png'" class="icon-contact">
              </span>
        </el-col>

      </el-form-item>


      <el-form-item :label="$t('accessory.room')">
        <el-select v-model="accessoryData.roomId" :placeholder="$t('accessory.select-room')">
          <el-option v-for="room in $store.getters.rooms" :label="room.name" :value="room.id" :key="room.id"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item prop="favorite" :label="$t('accessory.favorite')" style="width: 400px" v-if="false">
        <el-switch v-model="accessoryData.favorite" ></el-switch>
      </el-form-item>


      <span style="color: #606266;">{{ $t('accessory.camera.snapshot') }}</span>
      <div class="box" style="margin-bottom: 20px">
        <el-form-item prop="snapshotUrl" :label="$t('accessory.camera.url')" style="width: 500px">
          <el-input type="text" v-model="accessoryData.snapshotUrl" auto-complete="on" :placeholder="$t('accessory.camera.type-snapshot-url')">
          </el-input>
        </el-form-item>

        <el-form-item prop="username" :label="$t('accessory.camera.username')" style="width: 400px">
          <el-input type="text" v-model="accessoryData.username" auto-complete="on" :placeholder="$t('accessory.camera.type-username')">
          </el-input>
        </el-form-item>

        <el-form-item prop="password" :label="$t('accessory.camera.password')" style="width: 400px">
          <el-input type="text" v-model="accessoryData.password" auto-complete="on" :placeholder="$t('accessory.camera.type-password')">
          </el-input>
        </el-form-item>
      </div>


      <span style="color: #606266">{{ $t('accessory.camera.rtsp-stream') }}</span>
      <div class="box">
        <el-form-item prop="localStreamUrl" :label="$t('accessory.camera.local-url')" style="width: 500px">
          <el-input type="text" v-model="accessoryData.localStreamUrl" auto-complete="on" :placeholder="$t('accessory.camera.type-local-url')">
          </el-input>
        </el-form-item>

        <el-form-item :label="$t('accessory.camera.display-mobile')" style="width: 400px">
          <el-switch v-model="displayOnMobile" ></el-switch>
        </el-form-item>

        <transition name="fade">
          <div v-if="displayOnMobile">
            <el-form-item prop="externalStreamUrl" :label="$t('accessory.camera.external-url')" style="width: 500px" :rules="[{required: true, message: $t('accessory.camera.type-ext-url')}]">
              <el-input type="text" v-model="accessoryData.externalStreamUrl" auto-complete="on" :placeholder="$t('accessory.camera.type-ext-url')">
              </el-input>
            </el-form-item>

            <el-form-item prop="enableDynamicIP" :label="$t('accessory.camera.dynamic-ip')" style="width: 400px">
              <el-switch v-model="accessoryData.enableDynamicIP" ></el-switch>
            </el-form-item>
          </div>

        </transition>

      </div>

      <el-form-item style="text-align: left; margin-top: 20px" label-width="0px">
        <el-row v-for="(cameraAction, index) in accessoryData.cameraActions" :key="index" style="margin-top: 20px" :gutter="10">
          <el-col :span="3">
            <el-switch v-model="cameraAction.shortcut" :active-text="$t('accessory.shortcut')" style="margin-top: 10px"></el-switch>
          </el-col>

          <el-col :span="4" style="margin-left: 20px">
            <el-select v-model="cameraAction.actionType" :placeholder="$t('accessory.select-type')" filterable style="width: 120px" @change="changeActionType(cameraAction)">
              <el-option value="HTTP" :label="$t('accessory.http')"></el-option>
              <el-option value="ACCESSORY" :label="$t('accessory.accessories')"></el-option>
            </el-select>
          </el-col>


          <el-col :span="4">
            <el-input type="text" v-model="cameraAction.name" :placeholder="$t('accessory.action-name')">
            </el-input>
          </el-col>

          <el-col :span="9">
            <el-input v-if="cameraAction.actionType != 'ACCESSORY'" type="text" v-model="cameraAction.params.command" :placeholder="$t('accessory.type-http')">
            </el-input>

            <div v-if="cameraAction.actionType == 'ACCESSORY'">
              <el-select v-model="cameraAction.params.id" :placeholder="$t('accessory.select-accessory')" filterable @change="changeAccessory(cameraAction)">

                <el-option v-for="accessory in accessories" :value="accessory.id" :label="accessory.name" :key="accessory.id"></el-option>

              </el-select>

              <el-select v-model="cameraAction.params.command" :placeholder="$t('accessory.select-command')" filterable style="width: 100px; margin-left: 10px">
                <el-option value="ON" :label="$t('accessory.camera.on')"></el-option>
                <el-option value="OFF" :label="$t('accessory.camera.off')"></el-option>
              </el-select>

            </div>
          </el-col>

          <el-col :span="1">
            <image-picker :action="cameraAction" :selectedImage="cameraAction.icon" :images="actionIcons.map(icon => icon.value)" @selectImage="selectImage"></image-picker>
          </el-col>

          <el-col :span="1">
            <el-button @click="accessoryData.cameraActions.splice(index, 1)" type="danger"><i class="far fa-trash-alt"></i></el-button>
          </el-col>

        </el-row>

        <el-button @click="addAction" type="primary" style="margin-top: 20px">{{ $t('accessory.add-action') }}</el-button>
      </el-form-item>

      <el-form-item style="text-align: left; margin-top: 20px" label-width="0px">
        <el-button type="primary" @click.native.prevent="saveAccessory" :loading="loading">{{ $t('accessory.save') }}</el-button>
      </el-form-item>

    </el-form>



  </div>
</template>

<script>
  import ImagePicker from '@/components/ImagePicker'
  import Navigation from '@/components/Navigation'
  import {DEFAULT_ROOM_ID, className, group, ACCESSORY_CATEGORY_IPCAMERA} from "@/utils/accessory-utils";
  import {accessoryMixin} from "../accessory/components/accessoryMixin";

  export default {
    name: "IPCameraAddOrEdit",

    mixins: [accessoryMixin],

    components: {
      'app-navigation': Navigation,
      ImagePicker
    },

    data: function () {
      return {
        accessoryData: {
          roomId: DEFAULT_ROOM_ID,
          icon: "ic_ipcamera",
          category: ACCESSORY_CATEGORY_IPCAMERA,
          cameraActions: []
        },
        loading: false,
        displayOnMobile: false,

        actionIcons: [
          { title: this.$t('accessory.action-icons[0]'), value: "garage_door" },
          { title: this.$t('accessory.action-icons[1]'), value: "lighting_circle" },
          { title: this.$t('accessory.action-icons[2]'), value: "sos" },
          { title: this.$t('accessory.action-icons[3]') , value: "unlock_door" },
        ]
      }
    },

    computed:{
      accessories: function () {
        return this.$store.getters.accessories.filter(accessory => className(accessory.category) == "HHAccessorySwitch")
      }
    },

    methods: {
      className,
      group,

      checkActions: function(){
        var valid = true

        if (this.accessoryData.cameraActions && this.accessoryData.cameraActions.length > 0) {
          var message = this.$t('intercom.form-rules.check-fields')

          this.accessoryData.cameraActions.forEach(action => {
            var msg = []
            if (!action.name) {
              valid = false
              msg.push(this.$t('intercom.form-rules.name-empty'))
            }

            if (action.actionType == "HTTP") {
              if (!action.params.command) {
                valid = false
                msg.push(this.$t('intercom.form-rules.url-empty'))
              }
            } else if (action.actionType == "ACCESSORY") {
              if (!action.params.command) {
                valid = false
                msg.push(this.$t('intercom.form-rules.command-empty'))
              }
              if (!action.params.id) {
                valid = false
                msg.push(this.$t('intercom.form-rules.accessory-empty'))
              }
            }

            if (msg.length > 0) {
              const msgStr = msg.join(", ")
              message += "Action(" + action.actionType + ") " + msgStr + "<br>"
            }

          })

          if (!valid) {
            this.$message({ message: message, type: "error", dangerouslyUseHTMLString: true })
          }
        }

        return valid
      },

      saveAccessory(e){
        this.$refs.accessoryForm.validate((valid) => {
          if (valid && this.checkActions()) {
            this.loading = true

            console.log("save1", this.accessoryData)

            if (!this.displayOnMobile) {
              this.accessoryData.externalStreamUrl = ""
            }

            if (!this.accessoryData.localStreamUrl) {
              this.accessoryData.localStreamUrl = ""
            }

            console.log("save2", this.accessoryData)

            this.accessoryData.group = this.group(this.accessoryData.category)



            const data = {
              type: this.className(this.accessoryData.category),
              properties: this.accessoryData
            }

            if (this.$route.params.id) {
              this.$store.dispatch('editIPCamera', data).then(response => {
                this.loading = false
                this.$message( {type: "success", message: this.$t(response.message)} )

                this.$router.push({name: "IPCameras"})
              }).catch(error => {
                this.loading = false
                this.$message( {type: "error", message: this.$t(error)} )
              })
            } else {
              this.$store.dispatch('addIPCamera', data).then(response => {
                this.loading = false
                this.$message( {type: "success", message: this.$t(response.message)} )

                this.$router.push({name: "IPCameras"})
              }).catch(error => {
                this.loading = false
                this.$message( {type: "error", message: this.$t(error)} )
              })
            }

          }
        })
      },

      changeActionType: function(action){
        action.params = {}
      },

      selectImage: function(image, action){
        /*if (action.actionType !== "ACCESSORY") {
          action.icon = "contact/" + image
        } else {
          action.icon = "accessory/" + image + "_on"
        }*/
        action.icon = image
      },

      addAction: function () {
        console.log("Action Added...");
        this.accessoryData = {
          ...this.accessoryData,
          cameraActions: [...this.accessoryData.cameraActions, {
            name: "",
            actionType: "ACCESSORY",
            icon: this.actionIcons[0].value,
            shortcut: false,
            params: {}
          }]
        };
      },

      changeAccessory: function(action){

        console.log("Accessory Changed...");

        const accessory = this.accessories.find(accessory => accessory.id == action.params.id)
        if (accessory) {
          action.name = accessory.name
          action.icon = accessory.icon
        }
      },

    },

    created(){
      if (this.$route.params.id) {
        this.accessoryData = this.$store.getters.ipcameras.find(accessory => accessory.id == this.$route.params.id)
        if(!this.accessoryData.cameraActions){
          this.accessoryData.cameraActions = []
        }
      } else {
        this.accessoryData = {
          roomId: DEFAULT_ROOM_ID,
          icon: "ic_ipcamera",
          category: ACCESSORY_CATEGORY_IPCAMERA,
          cameraActions: [],
          localStreamUrl: "",
          snapshotUrl: "",
          username: "",
          password: ""
        }
      }

      this.displayOnMobile = this.accessoryData.externalStreamUrl ? true : false

    }
  }
</script>

<style scoped>
  .form-container {
    margin-right: 20px;
    margin-left: 20px;
    padding: 25px 25px 25px 25px;
    border: 1px solid #eaeaea;
  }

  .icon-select-layout{
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .icon-select {
    filter: brightness(0.45);
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }

  .icon-contact {
    filter: brightness(0.45);
    width: 40px;
    height: 40px;
  }

  .line {
    height: 1px;
    margin: 0px 0px 20px 0px;
    background-color: #ccc;
  }

  .box {
    border: 1px solid #eeeeee;
    border-radius: 10px;
    padding: 25px 15px 0px 35px;
    background-color: #eeeeee;
    margin-top: 5px;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>