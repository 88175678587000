var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("app-navigation", {
        attrs: {
          title: _vm.$route.params.id
            ? _vm.$t("accessory.camera.edit")
            : _vm.$t("accessory.camera.add"),
          backButtonText: _vm.$t("navigation.back")
        }
      }),
      _c(
        "el-form",
        {
          ref: "accessoryForm",
          staticClass: "form-container",
          attrs: {
            model: _vm.accessoryData,
            "label-position": "left",
            "label-width": "220px",
            "status-icon": ""
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticStyle: { width: "400px" },
              attrs: {
                prop: "name",
                label: _vm.$t("accessory.accessory-name"),
                rules: [
                  {
                    required: true,
                    message: _vm.$t("accessory.form-rules.type-name")
                  }
                ]
              }
            },
            [
              _c("el-input", {
                attrs: {
                  type: "text",
                  "auto-complete": "on",
                  placeholder: _vm.$t("accessory.type-accessory-name")
                },
                model: {
                  value: _vm.accessoryData.name,
                  callback: function($$v) {
                    _vm.$set(_vm.accessoryData, "name", $$v)
                  },
                  expression: "accessoryData.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("accessory.icon") } },
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: _vm.$t("accessory.select-icon")
                      },
                      model: {
                        value: _vm.accessoryData.icon,
                        callback: function($$v) {
                          _vm.$set(_vm.accessoryData, "icon", $$v)
                        },
                        expression: "accessoryData.icon"
                      }
                    },
                    _vm._l(_vm.icons, function(icon, index) {
                      return _c(
                        "el-option",
                        {
                          key: index,
                          attrs: { label: icon.title, value: icon.value }
                        },
                        [
                          _c("span", { staticClass: "icon-select-layout" }, [
                            _c("img", {
                              staticClass: "icon-select",
                              attrs: {
                                src: "/assets/icons/" + icon.value + ".png"
                              },
                              on: { error: _vm.onImageError }
                            }),
                            _vm._v(_vm._s(icon.title))
                          ])
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c("el-col", { attrs: { span: 16 } }, [
                _c("span", [
                  _c("img", {
                    staticClass: "icon-contact",
                    attrs: {
                      src: "/assets/icons/" + _vm.accessoryData.icon + ".png"
                    },
                    on: { error: _vm.onImageError }
                  })
                ])
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("accessory.room") } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: _vm.$t("accessory.select-room") },
                  model: {
                    value: _vm.accessoryData.roomId,
                    callback: function($$v) {
                      _vm.$set(_vm.accessoryData, "roomId", $$v)
                    },
                    expression: "accessoryData.roomId"
                  }
                },
                _vm._l(_vm.$store.getters.rooms, function(room) {
                  return _c("el-option", {
                    key: room.id,
                    attrs: { label: room.name, value: room.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          false
            ? _c(
                "el-form-item",
                {
                  staticStyle: { width: "400px" },
                  attrs: {
                    prop: "favorite",
                    label: _vm.$t("accessory.favorite")
                  }
                },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.accessoryData.favorite,
                      callback: function($$v) {
                        _vm.$set(_vm.accessoryData, "favorite", $$v)
                      },
                      expression: "accessoryData.favorite"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c("span", { staticStyle: { color: "#606266" } }, [
            _vm._v(_vm._s(_vm.$t("accessory.camera.snapshot")))
          ]),
          _c(
            "div",
            { staticClass: "box", staticStyle: { "margin-bottom": "20px" } },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "500px" },
                  attrs: {
                    prop: "snapshotUrl",
                    label: _vm.$t("accessory.camera.url")
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      "auto-complete": "on",
                      placeholder: _vm.$t("accessory.camera.type-snapshot-url")
                    },
                    model: {
                      value: _vm.accessoryData.snapshotUrl,
                      callback: function($$v) {
                        _vm.$set(_vm.accessoryData, "snapshotUrl", $$v)
                      },
                      expression: "accessoryData.snapshotUrl"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "400px" },
                  attrs: {
                    prop: "username",
                    label: _vm.$t("accessory.camera.username")
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      "auto-complete": "on",
                      placeholder: _vm.$t("accessory.camera.type-username")
                    },
                    model: {
                      value: _vm.accessoryData.username,
                      callback: function($$v) {
                        _vm.$set(_vm.accessoryData, "username", $$v)
                      },
                      expression: "accessoryData.username"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "400px" },
                  attrs: {
                    prop: "password",
                    label: _vm.$t("accessory.camera.password")
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      "auto-complete": "on",
                      placeholder: _vm.$t("accessory.camera.type-password")
                    },
                    model: {
                      value: _vm.accessoryData.password,
                      callback: function($$v) {
                        _vm.$set(_vm.accessoryData, "password", $$v)
                      },
                      expression: "accessoryData.password"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("span", { staticStyle: { color: "#606266" } }, [
            _vm._v(_vm._s(_vm.$t("accessory.camera.rtsp-stream")))
          ]),
          _c(
            "div",
            { staticClass: "box" },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "500px" },
                  attrs: {
                    prop: "localStreamUrl",
                    label: _vm.$t("accessory.camera.local-url")
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      "auto-complete": "on",
                      placeholder: _vm.$t("accessory.camera.type-local-url")
                    },
                    model: {
                      value: _vm.accessoryData.localStreamUrl,
                      callback: function($$v) {
                        _vm.$set(_vm.accessoryData, "localStreamUrl", $$v)
                      },
                      expression: "accessoryData.localStreamUrl"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "400px" },
                  attrs: { label: _vm.$t("accessory.camera.display-mobile") }
                },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.displayOnMobile,
                      callback: function($$v) {
                        _vm.displayOnMobile = $$v
                      },
                      expression: "displayOnMobile"
                    }
                  })
                ],
                1
              ),
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.displayOnMobile
                  ? _c(
                      "div",
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { width: "500px" },
                            attrs: {
                              prop: "externalStreamUrl",
                              label: _vm.$t("accessory.camera.external-url"),
                              rules: [
                                {
                                  required: true,
                                  message: _vm.$t(
                                    "accessory.camera.type-ext-url"
                                  )
                                }
                              ]
                            }
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "text",
                                "auto-complete": "on",
                                placeholder: _vm.$t(
                                  "accessory.camera.type-ext-url"
                                )
                              },
                              model: {
                                value: _vm.accessoryData.externalStreamUrl,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.accessoryData,
                                    "externalStreamUrl",
                                    $$v
                                  )
                                },
                                expression: "accessoryData.externalStreamUrl"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { width: "400px" },
                            attrs: {
                              prop: "enableDynamicIP",
                              label: _vm.$t("accessory.camera.dynamic-ip")
                            }
                          },
                          [
                            _c("el-switch", {
                              model: {
                                value: _vm.accessoryData.enableDynamicIP,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.accessoryData,
                                    "enableDynamicIP",
                                    $$v
                                  )
                                },
                                expression: "accessoryData.enableDynamicIP"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { "text-align": "left", "margin-top": "20px" },
              attrs: { "label-width": "0px" }
            },
            [
              _vm._l(_vm.accessoryData.cameraActions, function(
                cameraAction,
                index
              ) {
                return _c(
                  "el-row",
                  {
                    key: index,
                    staticStyle: { "margin-top": "20px" },
                    attrs: { gutter: 10 }
                  },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 3 } },
                      [
                        _c("el-switch", {
                          staticStyle: { "margin-top": "10px" },
                          attrs: {
                            "active-text": _vm.$t("accessory.shortcut")
                          },
                          model: {
                            value: cameraAction.shortcut,
                            callback: function($$v) {
                              _vm.$set(cameraAction, "shortcut", $$v)
                            },
                            expression: "cameraAction.shortcut"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      {
                        staticStyle: { "margin-left": "20px" },
                        attrs: { span: 4 }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "120px" },
                            attrs: {
                              placeholder: _vm.$t("accessory.select-type"),
                              filterable: ""
                            },
                            on: {
                              change: function($event) {
                                return _vm.changeActionType(cameraAction)
                              }
                            },
                            model: {
                              value: cameraAction.actionType,
                              callback: function($$v) {
                                _vm.$set(cameraAction, "actionType", $$v)
                              },
                              expression: "cameraAction.actionType"
                            }
                          },
                          [
                            _c("el-option", {
                              attrs: {
                                value: "HTTP",
                                label: _vm.$t("accessory.http")
                              }
                            }),
                            _c("el-option", {
                              attrs: {
                                value: "ACCESSORY",
                                label: _vm.$t("accessory.accessories")
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 4 } },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "text",
                            placeholder: _vm.$t("accessory.action-name")
                          },
                          model: {
                            value: cameraAction.name,
                            callback: function($$v) {
                              _vm.$set(cameraAction, "name", $$v)
                            },
                            expression: "cameraAction.name"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 9 } },
                      [
                        cameraAction.actionType != "ACCESSORY"
                          ? _c("el-input", {
                              attrs: {
                                type: "text",
                                placeholder: _vm.$t("accessory.type-http")
                              },
                              model: {
                                value: cameraAction.params.command,
                                callback: function($$v) {
                                  _vm.$set(cameraAction.params, "command", $$v)
                                },
                                expression: "cameraAction.params.command"
                              }
                            })
                          : _vm._e(),
                        cameraAction.actionType == "ACCESSORY"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "accessory.select-accessory"
                                      ),
                                      filterable: ""
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.changeAccessory(cameraAction)
                                      }
                                    },
                                    model: {
                                      value: cameraAction.params.id,
                                      callback: function($$v) {
                                        _vm.$set(cameraAction.params, "id", $$v)
                                      },
                                      expression: "cameraAction.params.id"
                                    }
                                  },
                                  _vm._l(_vm.accessories, function(accessory) {
                                    return _c("el-option", {
                                      key: accessory.id,
                                      attrs: {
                                        value: accessory.id,
                                        label: accessory.name
                                      }
                                    })
                                  }),
                                  1
                                ),
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: {
                                      width: "100px",
                                      "margin-left": "10px"
                                    },
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "accessory.select-command"
                                      ),
                                      filterable: ""
                                    },
                                    model: {
                                      value: cameraAction.params.command,
                                      callback: function($$v) {
                                        _vm.$set(
                                          cameraAction.params,
                                          "command",
                                          $$v
                                        )
                                      },
                                      expression: "cameraAction.params.command"
                                    }
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: {
                                        value: "ON",
                                        label: _vm.$t("accessory.camera.on")
                                      }
                                    }),
                                    _c("el-option", {
                                      attrs: {
                                        value: "OFF",
                                        label: _vm.$t("accessory.camera.off")
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 1 } },
                      [
                        _c("image-picker", {
                          attrs: {
                            action: cameraAction,
                            selectedImage: cameraAction.icon,
                            images: _vm.actionIcons.map(function(icon) {
                              return icon.value
                            })
                          },
                          on: { selectImage: _vm.selectImage }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 1 } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.accessoryData.cameraActions.splice(
                                  index,
                                  1
                                )
                              }
                            }
                          },
                          [_c("i", { staticClass: "far fa-trash-alt" })]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              }),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.addAction }
                },
                [_vm._v(_vm._s(_vm.$t("accessory.add-action")))]
              )
            ],
            2
          ),
          _c(
            "el-form-item",
            {
              staticStyle: { "text-align": "left", "margin-top": "20px" },
              attrs: { "label-width": "0px" }
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  nativeOn: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.saveAccessory($event)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("accessory.save")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }